.iframe-phone-component {
  display: inline-block;
  &__phone {
    border: 40px solid #ddd;
    border-width: 55px 7px;
    border-radius: 40px;
    margin: 50px auto;
    overflow: hidden;
    transition: all 0.5s ease;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    box-shadow: 0 3px 0 #BBB, 0 4px 0 #BBB, 0 5px 0 #BBB, 0 7px 0 #BBB, 0 10px 20px #ccc;
    iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }
  }
}